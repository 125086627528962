import { mapGetters, mapMutations, mapActions } from "vuex";
import icons from "../../../../mixins/icons/index.js";
import * as moment from "moment/moment";
const _ = require("lodash");

export default {
  props: ["popupType", "newProduct"],
  mixins: [icons],
  components: {
    orderHistory: () => import("./order-history"),
    confirmClose: () => import("../confirmCloseOrder/index.vue"),
  },
  data() {
    return {
      deliveryOmitStatuses: [1, 2, 3, 54],
      ukrPostOmitStatuses: [1, 2, 3, 54],
      newPostOmitStatuses: [1, 2, 3, 54],
      toggleTextAddress: false,
      toggleInputPrice: false,
      toggleInputCount: false,
      toggleUpSell: false,
      blockAddProduct: false,
      dirtyUpSell: false,
      toggleEditPProduct: false,
      form: {
        department_id: 0,
        customer_name: "",
        email: "",
        country: "",
        phone: 0,
        wholesale_phone: 0,
        wholesale_order: 0,
        site: "",
        ip: "",
        comment: "",
        cancel_reason: "0",
        shipment_method_id: 0,
        order_status_id: 1,
        products: [],
        ttn: "",
        list_ttn: [],
        index: "",
        area_guid: "",
        city_guid: "",
        warehouse_guid: "",
        seats: [],
        address: "",
        house: "",
        flat: "",
        city: "",
        type: null,
        site_id: null,
        hot_order: 0,
        total_cost: "",
        manager_comment: "",
        comment_warehouse: "",
        order_data_shipments: {
          type_shipment: "STANDARD",
          prepayment_amount: "",
          shipment_id: 0,
          type_delivery: "",
          area: "",
          city: "",
          warehouse: "",
          street: "",
          house: "",
          apartment: "",
          area_name: "",
          city_name: "",
          street_name: "",
          house_name: "",
          warehouse_name: "",
        },
      },
      startForm: [],
      typeShipmentList: [
        {
          id: "STANDARD",
          title: "Стандарт",
        },
        {
          id: "EXPRESS",
          title: "Експрес",
        },
      ],
      deliveryType: [],
      deliveryChoice: 0,
      ttn: "",
      fields: [
        {
          key: "product.id",
          label: "id",
        },
        {
          key: "image",
          label: "Фото",
        },
        {
          key: "product",
          label: this.$t("global.product"),
        },
        {
          key: "price",
          label: this.$t("global.price"),
        },
        {
          key: "count",
          label: "К-сть",
        },
        {
          key: "order_product_reserves.quantity",
          label: "Резерв",
        },
        {
          key: "color",
          label: "Колір",
        },
        {
          key: "product.quantity",
          label: "Склад",
        },
        {
          key: "result",
          label: "Сума",
        },
        {
          key: "orderProductRemove",
          label: "",
        },
      ],
      tableItems: [],
      cancelReasonList: [
        {
          id: "0",
          title: "-Нет значения-",
        },
        {
          id: "1",
          title: "Дубль",
        },
        {
          id: "2",
          title: "Відмовилися",
        },
        {
          id: "3",
          title: "Не робив замовлення",
        },
        {
          id: "4",
          title: "Недійсний номер",
        },
        {
          id: "5",
          title: "Недооформлені замовлення",
        },
        {
          id: "6",
          title: "Повернення на склад",
        },
      ],
      ukrposhtaStreetList: [],
      ukrposhtaHousetList: [],
      ukrposhtaStreet: null,
      ukrposhtaHouse: null,
      ukrposhtaFlat: null,
      npAreasList: [],
      npCityListClient: [],
      npWarehousesListClient: [],
      typeDeliveryList: [
        {
          title: "Віділення",
          id: "WarehouseWarehouse",
        },
        {
          title: "Адрес",
          id: "WarehouseDoors",
        },
      ],
      deliveryAreasList: [],
      availableFalseCountProduct: false,
      toSendOneCStatus: false,
      orderValidate: false,
      npStreetList: [],
      showConfirmPopup: false,
      ukrPostIndexList: [],
      changedFormStart: false,
      shipmentList: [
        {
          id: "standard",
          title: "Стандарт",
        },
        {
          id: "free",
          title: "За наш рахунок",
        },
        {
          id: "bonus",
          title: "Бонус",
        },
      ],
      productListForRemove: [],
    };
  },
  computed: {
    ...mapGetters({
      order: "orders/order",
      departments: "catalog/departments",
      shipmentMethods: "orders/shipmentMethods",
      orderStatusList: "orders/orderStatusList",
      paymentMethods: "orders/paymentMethods",
      usersGroup: "contacts/usersGroup",
      users: "contacts/users",
      profile: "profile/profile",
      ukrposhtaData: "orders/ukrposhtaData",
      ukrposhtaAddressList: "orders/ukrposhtaAddressList",
      deliveryAreas: "orders/deliveryAreas",
      deliveryCity: "orders/deliveryCity",
      deliveryWarehouses: "orders/deliveryWarehouses",
      sites: "catalog/sites",
      npStreet: "plugins/npStreet",
      prevUserOrder: "orders/prevUserOrder",
      orderFileLink: "orders/orderFileLink",
    }),
  },
  watch: {
    newProduct(e) {
      e.is_upsell = 1;
      if (e && this.validAddProduct(e)) {
        this.form.products.push({
          id: e.id,
          is_upsell: e.is_upsell,
          price: e.price,
          product: e.product,
          product_id: e.product_id,
          quantity: e.quantity,
        });
      } else {
        this.form.products.map((itemProduct) => {
          if (itemProduct.product_id === e.id) {
            itemProduct.quantity = itemProduct.quantity + e.quantity;
          }
        });
      }
      this.getProductStatus();
    },
    order(e) {
      if (e) {
        if (e.order_data_shipments) {
          this.getShipmentData(e.order_data_shipments);
        } else {
          e.order_data_shipments = {
            shipment_id: 0,
            type_delivery: "",
            area: "",
            city: "",
            warehouse: "",
            street: "",
            house: "",
            apartment: "",
          };
        }
        this.form = _.clone(e);
        this.getProductStatus();
        this.getOrderStatusValidate();
        if (this.form.order_data_shipments.shipment_id === 10) {
          if (this.form.order_data_shipments.area) {
            this.form.order_data_shipments.area = Number(
              this.form.order_data_shipments.area
            );
          }
          this.getDeliveryCities(this.form.order_data_shipments.area);
          this.getDeliveryWarehouses(this.form.order_data_shipments.city);
        } else if (this.form.order_data_shipments.shipment_id === 2) {
          this.form.order_data_shipments.warehouse_name = this.form
            .order_data_shipments.warehouse_name
            ? this.form.order_data_shipments.warehouse_name
            : this.form.order_data_shipments.warehouse;
        }
        if (this.form.order_data_shipments.warehouse) {
          const payload = {
            zip_code: this.form.order_data_shipments.warehouse,
          };
          this.getUkrPostIndexData(payload).then(() => {
            this.getUkrPostIndexAddress(payload);
          });
        }
        this.tableItems = [];
        if (!this.changedFormStart) {
          this.startForm = _.cloneDeep(this.form, true);
          this.startForm.total_cost = Number(this.startForm.total_cost);
          this.changedFormStart = true;
        }
      }
      this.findContactForWholesaler();
    },
    toggleUpSell() {
      this.dirtyUpSell = true;
    },
    form() {
      this.changedOrderStatus();
    },

    ukrposhtaAddressList(e) {
      e.models.map((item) => {
        let newStreetName = true;
        if (!this.ukrposhtaStreetList.length) {
          this.pushUkrPoshtaInStreetList(item);
        } else {
          this.ukrposhtaStreetList.map((streetItem) => {
            if (streetItem.STREET_ID === item.STREET_ID) {
              newStreetName = false;
            }
          });
          if (newStreetName) {
            this.pushUkrPoshtaInStreetList(item);
          }
        }
      });
    },
    npCityListClient() {
      this.changeSenderCityClient(false);
      if (this.form.order_data_shipments.city) {
        this.getNpStreet({
          city_id: this.form.order_data_shipments.city,
          page: 1,
        });
      }
    },
    npStreet(e) {
      this.npStreetList = [];
      Object.keys(e.models).map((item) => {
        this.npStreetList.push({
          id: item,
          title: e.models[item],
        });
      });
      this.checkIncludeStreet();
    },
    npWarehousesListClient() {
      this.checkIncludeNpWarehouse();
    },
  },
  created() {
    this.getUsers();
    this.getUsersGroup();
    this.shipmentMethods.data.map((item) => {
      this.deliveryType.push(item);
    });

    this.getNpAreas().then((res) => {
      if (res.status) {
        let key;
        for (key in res.data.models) {
          this.npAreasList.push({
            id: key,
            title: res.data.models[key],
          });
        }
      }
    });
    Object.keys(this.deliveryAreas.models).map((item) => {
      const deliveryItem = this.deliveryAreas.models[item];
      this.deliveryAreasList.push({
        id: deliveryItem.id,
        title: deliveryItem.name,
      });
    });
  },
  methods: {
    validAddProduct(e) {
      return !this.form.products.find((item) => item.product_id === e.id);
    },
    loadOrderFile() {
      this.getOrderFileLinks(this.order.id).then((res) => {
        if (res.status) {
          let route = this.$router.resolve({ path: this.orderFileLink.models });
          console.log("route - ", route);
          // let route = this.$router.resolve('/link/to/page'); // This also works.
          window.open(route.location.params.pathMatch, "_blank");
        } else {
          this.$toasted.error(JSON.stringify(res.data.message), {
            duration: 3000,
          });
        }
      });
    },
    findContactForWholesaler: _.debounce(function() {
      if (this.form.phone.length >= 10) {
        this.getCustomers({ phone: this.form.phone }).then((res) => {
          if (res.status) {
            if (res.data.data.length && res.data.data[0].wholesale) {
              const actualSite = this.sites.data.filter(
                (item) => item.id === this.form.site_id
              )[0];
              this.form.wholesale_order = actualSite.is_wholesale;
            } else {
              this.form.wholesale_order = 0;
            }
          } else {
            this.$toasted.error(JSON.stringify(res.data), {
              duration: 3000,
            });
          }
        });
      }
    }, 300),
    showPrevOrder() {
      this.showOrder(this.prevUserOrder.id).then(() => {
        this.showUserOrder(null);
      });
    },
    getProductStatus() {
      let availableFalseCountProduct = false;
      this.form.products.map((product) => {
        if (product.quantity > product.product.quantity) {
          availableFalseCountProduct = true;
        }
      });
      if (availableFalseCountProduct) {
        this.availableFalseCountProduct = true;
      }
    },
    getOrderStatusValidate() {
      const orderStatus = this.orderStatusList.data.filter(
        (item) => item.id === this.form.order_status_id
      )[0];
      this.toSendOneCStatus = orderStatus.sent_to_1c;
      this.orderValidate = orderStatus.is_validate;
    },
    showAddressInput() {
      this.toggleTextAddress = true;
      setTimeout(() => {
        this.$refs.address.focus();
      }, 100);
    },
    addressValidation() {
      switch (this.form.order_data_shipments.shipment_id) {
        case 1:
          return this.validateNewPost();
        case 2:
          return this.validateUkrPost();
        case 3:
          return true;
        case 10:
          return this.validateDelivery();
        case 11:
          return true;
      }
    },
    validateNewPost() {
      if (this.form.order_data_shipments.type_delivery) {
        switch (this.form.order_data_shipments.type_delivery) {
          case "WarehouseWarehouse":
            return this.validateNewPostWarehouse();
          case "WarehouseDoors":
            return this.validateNewPostArea();
        }
      } else {
        return false;
      }
    },
    validateNewPostWarehouse() {
      const area = this.form.order_data_shipments.area;
      const city = this.form.order_data_shipments.city;
      const warehouse = this.form.order_data_shipments.warehouse;
      return Boolean(area && city && warehouse);
    },
    validateNewPostArea() {
      const area = this.form.order_data_shipments.area;
      const city = this.form.order_data_shipments.city;
      const street = this.form.order_data_shipments.street;
      const house = this.form.order_data_shipments.house;
      return Boolean(area && city && street && house);
    },
    validateUkrPost() {
      return Boolean(this.form.order_data_shipments.warehouse);
    },
    validateDelivery() {
      const area = this.form.order_data_shipments.area;
      const city = this.form.order_data_shipments.city;
      const warehouse =
        this.form.order_data_shipments.type_delivery === "WarehouseDoors"
          ? true
          : this.form.order_data_shipments.warehouse;
      console.log("area - ", area);
      console.log("city - ", city);
      console.log("warehouse - ", warehouse);
      console.log(
        "Boolean(area && city && warehouse) - ",
        Boolean(area && city && warehouse)
      );
      return Boolean(area && city && warehouse);
    },
    getSearchWarehouse: _.debounce(function(search) {
      this.getNpWarehouseAction(
        this.form.order_data_shipments.city,
        false,
        search
      );
    }, 500),
    searchNpStreet: _.debounce(function(search) {
      this.getNpStreet({
        city_id: this.form.order_data_shipments.city,
        page: 1,
        name: search,
      });
    }, 500),
    validCancel() {
      return this.form.order_status_id === 50;
    },
    getShipmentData(orderDataShipment) {
      if (
        orderDataShipment.shipment_id === 1 &&
        orderDataShipment.type_delivery
      ) {
        if (orderDataShipment.area) {
          this.getNpCityAction(orderDataShipment.area, false);
        }
        if (
          orderDataShipment.type_delivery &&
          orderDataShipment.type_delivery === "Warehouse" &&
          orderDataShipment.city
        ) {
          this.getNpWarehouseAction(orderDataShipment.city, false);
        }
      }
    },

    choiceDeliveryArea() {
      this.form.order_data_shipments.city = null;
      this.form.order_data_shipments.warehouse = null;
      this.form.order_data_shipments.warehouse_name = "";
      const choiceArea = this.deliveryAreasList.filter(
        (item) => item.id === Number(this.form.order_data_shipments.area)
      )[0];
      this.form.order_data_shipments.area_name = choiceArea.title;
      this.getDeliveryCities(this.form.order_data_shipments.area);
    },
    choiceDeliveryCity() {
      this.form.order_data_shipments.warehouse = null;
      this.form.order_data_shipments.warehouse_name = "";
      const choiceCity = this.deliveryCity.filter(
        (item) => item.id === this.form.order_data_shipments.city
      )[0];
      this.form.order_data_shipments.city_name = choiceCity.name;
      this.getDeliveryWarehouses(this.form.order_data_shipments.city);
    },
    choiceDeliveryWarehouse() {
      const choiceWarehouse = this.deliveryWarehouses.filter(
        (item) => item.id === this.form.order_data_shipments.warehouse
      )[0];
      this.form.order_data_shipments.warehouse_name = choiceWarehouse.name;
    },
    changeShipmentType() {
      this.form.order_data_shipments.area = "";
      this.form.order_data_shipments.warehouse = "";
      this.form.order_data_shipments.city = "";
      this.form.order_data_shipments.street = "";
      this.form.order_data_shipments.house = "";
      this.form.order_data_shipments.apartment = "";
    },
    // ukrPost address search

    ukrposhtaSearchIndex: _.debounce(function(index) {
      if (index.length === 5) {
        const payload = {
          zip_code: index,
        };
        this.ukrposhtaStreetList = [];
        this.ukrposhtaHousetList = [];
        this.ukrPostIndexList = [];
        this.getUkrPostIndexData(payload).then((res) => {
          if (res.result) {
            res.data.models.map((item) => {
              this.ukrPostIndexList.push({
                id: item.ID,
                code: item.POSTCODE,
                title: item.PO_SHORT,
                address: item.ADDRESS,
              });
            });
          }
        });
      }
    }, 500),
    choiceUkrPostIndex(item) {
      const payload = {
        zip_code: item.code,
      };
      console.log("getUkrPostIndexAddress  01");
      this.getUkrPostIndexAddress(payload).then((res) => {
        if (res.result) {
          this.form.order_data_shipments.warehouse_name =
            item.code + ", " + item.title + ", " + item.address;
          this.form.order_data_shipments.warehouse = item.code;
          this.ukrPostIndexList = [];
          this.form.order_data_shipments.city = "";
          this.form.order_data_shipments.street = "";
          this.form.order_data_shipments.house = "";
          this.form.order_data_shipments.apartment = "";
        }
      });
    },
    pushUkrPoshtaInStreetList(item) {
      this.ukrposhtaStreetList.push({
        CITYTYPE_NAME: item.CITYTYPE_NAME,
        CITY_NAME: item.CITY_NAME,
        STREET_ID: item.STREET_ID,
        STREET_NAME: item.STREET_NAME,
      });
    },

    getHousesFromStreet() {
      this.ukrposhtaAddressList.models.map((item) => {
        if (item.STREET_ID === this.form.order_data_shipments.street) {
          this.ukrposhtaHousetList.push({
            HOUSENUMBER: item.HOUSENUMBER,
          });
        }
      });
      this.form.order_data_shipments.street_name = this.ukrposhtaStreetList.filter(
        (item) => item.STREET_ID === this.form.order_data_shipments.street
      )[0].STREET_NAME;
    },

    // ukrPost address search end

    // newPost address search

    changeSenderAreasClient() {
      this.npCityListClient = [];
      this.npWarehousesListClient = [];
      this.getNpCityAction(this.form.order_data_shipments.area, false);
      this.form.order_data_shipments.city = null;
      this.form.order_data_shipments.warehouse = null;

      const area_name = this.npAreasList.filter(
        (item) => item.id === this.form.order_data_shipments.area
      )[0];
      this.form.order_data_shipments.area_name = area_name.title;
    },

    changeSenderCityClient(status) {
      if (status) {
        this.form.order_data_shipments.warehouse = "";
      }

      if (
        this.form.order_data_shipments.type_delivery === "WarehouseWarehouse"
      ) {
        this.getNpWarehouseAction(this.form.order_data_shipments.city, false);
      }
      const city_name = this.npCityListClient.filter(
        (item) => item.id === this.form.order_data_shipments.city
      );
      this.form.order_data_shipments.city_name = city_name.length
        ? city_name[0].title
        : "-";
    },
    changeStreetName() {
      this.form.order_data_shipments.street_name = this.form.order_data_shipments.street;
    },
    getNpCityAction(id, sender) {
      this.getNpCity({ area_id: id }).then((res) => {
        if (res.status && sender) {
          this.npCityList = [];
          let key;
          for (key in res.data.models) {
            this.npCityList.push({
              id: key,
              title: res.data.models[key],
            });
          }
        } else if (res.status && !sender) {
          this.npCityListClient = [];
          let key;
          for (key in res.data.models) {
            this.npCityListClient.push({
              id: key,
              title: res.data.models[key],
            });
          }
        }
      });
    },

    getNpWarehouseAction(id, sender, search) {
      this.getNpWarehouses({
        page: 1,
        city_id: id,
        warehouse_id: search ? search : null,
      }).then((res) => {
        if (res.status && sender) {
          this.npWarehousesList = [];
          let key;
          for (key in res.data.models) {
            this.npWarehousesList.push({
              id: key,
              title: res.data.models[key],
            });
          }
        } else if (res.status && !sender) {
          this.npWarehousesListClient = [];
          let key;
          for (key in res.data.models) {
            this.npWarehousesListClient.push({
              id: key,
              title: res.data.models[key],
            });
          }
          if (!this.npWarehousesListClient.length) {
            this.npWarehousesListClient.push({
              id: 0,
              title: "Немає відділень",
            });
          }
        }
      });
    },

    saveWarehouse() {
      const npWarehouse = this.npWarehousesListClient.filter(
        (item) => item.id === this.form.order_data_shipments.warehouse
      )[0];
      this.form.order_data_shipments.warehouse_name = npWarehouse.title;
    },
    changeNpStreet() {
      const npCity = this.npCityListClient.filter(
        (item) => item.id === this.form.order_data_shipments.city
      )[0];
      this.form.order_data_shipments.city_name = npCity.title;
      if (
        this.form.order_data_shipments.type_delivery === "WarehouseWarehouse"
      ) {
        this.npWarehousesListClient = [];
        this.form.order_data_shipments.warehouse = "";
        this.getNpWarehouseAction(this.form.order_data_shipments.city, false);
      } else {
        this.form.order_data_shipments.street = "";
        this.npStreetList = [];
        this.getNpStreet({
          city_id: this.form.order_data_shipments.city,
          page: 1,
        });
      }
    },
    getCityName() {
      const npStreet = this.npStreetList.filter(
        (item) => item.id === this.form.order_data_shipments.street
      )[0];
      this.form.order_data_shipments.street_name = npStreet.title;
    },
    // newPost address search end

    changedOrderStatus() {
      if (
        this.form.order_status_id === 15 ||
        this.form.order_status_id === 22 ||
        this.form.order_status_id === 23
      ) {
        this.blockAddProduct = true;
      } else {
        this.blockAddProduct = false;
      }
      this.getOrderStatusValidate();
    },

    removeProduct(productItem) {
      this.productListForRemove.push(productItem);
      if (productItem.upSell === 1) {
        const index = this.tableUpItems.indexOf(productItem);
        this.tableUpItems.splice(index, 1);
      } else if (productItem.upSell === 0) {
        const index = this.tableItems.indexOf(productItem);
        this.tableItems.splice(index, 1);
      }
      const index = this.form.products.indexOf(productItem);
      this.form.products.splice(index, 1);
    },
    removeProductBeforeSave() {
      this.productListForRemove.map((item) => {
        this.removeProductInOrder(item.id).then((res) => {
          if (res.status) {
            this.$toasted.success("Товар успешно удален", {
              duration: 3000,
            });
          } else {
            this.$toasted.error("Произошла ошибка при удалении", {
              duration: 3000,
            });
          }
        });
        this.getProductStatus();
      });
    },
    findPhoneOrders() {
      this.$emit("openPhoneOrders");
      this.getOrdersForPhone({ phone: this.form.phone });
    },
    closePopup() {
      if (JSON.stringify(this.startForm) === JSON.stringify(this.form)) {
        this.changeOrder(null);
        this.$emit("closePopup");
      } else {
        this.showConfirmPopup = true;
      }
    },
    cancelClose() {
      this.showConfirmPopup = false;
    },
    confirmClose() {
      this.showConfirmPopup = false;
      this.changeOrder(null);
      this.$emit("closePopup");
    },
    openOrderChangesList() {
      this.$emit("openOrderChangesList", { form: this.form });
    },
    openRememberPopup() {
      this.$emit("togglePopupRemember", { status: true, form: this.form });
    },
    openPopupSearchProduct() {
      this.changeOrderWholesaleStatus(this.form.wholesale_order);
      this.changeIsUpsell(0);
      this.toggleShowAddProductPopUp(true);
      this.changeOrderDepartmentId(this.form.department_id);
    },
    openPopupUpSellProduct() {
      this.changeIsUpsell(1);
      this.toggleShowAddProductPopUp(true);
    },
    openNpTtn() {
      this.$emit("openNpTtn", { status: true, form: this.form });
    },
    openInfoTtn() {
      this.$emit("openInfoTtn");
    },
    sendChanges() {
      console.log("sendChanges");
      this.toggleStatusGetLoad = true;
      const customerNameList = this.form.customer_name.split(" ");
      if (this.orderValidate && !this.addressValidation()) {
        this.$toasted.error("Адреса замовлення заповненна не повністю.", {
          duration: 3000,
        });
        this.toggleStatusGetLoad = false;
      } else if (this.orderValidate && !this.changeStatusValidation()) {
        this.$toasted.error(
          "Увага! Товарів недостатньо на складі. Змініть кількість або видаліть виділені позиції.",
          {
            duration: 3000,
          }
        );
        this.toggleStatusGetLoad = false;
      } else if (
        // this.form.order_data_shipments.type_delivery &&
        // this.form.order_data_shipments.type_delivery === "WarehouseDoors" &&
        // this.validateFullName(customerNameList)
        // ukr_post = 2
        // delivery = 10
        // new_post = 1
        ((this.form.order_data_shipments.shipment_id === 10 &&
          !this.deliveryOmitStatuses.includes(+this.form.order_status_id)) ||
          (this.form.order_data_shipments.shipment_id === 2 &&
            this.form.payment_method_id === 15 &&
            !this.ukrPostOmitStatuses.includes(+this.form.order_status_id)) ||
          (this.form.order_data_shipments.shipment_id === 1 &&
            !this.newPostOmitStatuses.includes(+this.form.order_status_id) &&
            this.form.order_data_shipments.type_delivery ===
              "WarehouseDoors") ||
          ((this.form.order_data_shipments.shipment_id === 10 ||
            this.form.order_data_shipments.shipment_id === 2) &&
            +this.form.order_status_id === 5)) &&
        this.validateFullName(customerNameList)
      ) {
        this.$toasted.error("Заповніть повністю ПІБ покупця", {
          duration: 3000,
        });
        this.toggleStatusGetLoad = false;
      } else {
        this.validateShipmentMethodsForOrderStatus();
        this.removeProductBeforeSave();
        if (!this.form.employee_id) {
          this.form.employee_id = this.profile.id;
        }
        this.form.shipment_method_id = this.form.order_data_shipments.shipment_id;
        if (this.form.order_data_shipments.area) {
          this.form.order_data_shipments.area = this.form.order_data_shipments.area.toString();
        }
        this.form.total_cost = JSON.stringify(this.form.total_cost);
        this.form.total = Number(this.form.total_cost);

        if (this.order) {
          const payload = {
            id: this.order.id,
            data: this.form,
          };
          this.$emit("changeOrder", payload);
        } else {
          this.$emit("sendOrder", this.form);
        }
        this.toggleStatusGetLoad = false;
      }
    },
    validateFullName(nameList) {
      let validateNameStatus = false;
      if (nameList.length < 3) {
        validateNameStatus = true;
      } else {
        nameList.map((name) => {
          if (name.length === 0) {
            validateNameStatus = true;
          }
        });
      }
      return validateNameStatus;
    },
    changeStatusValidation() {
      let validate = true;

      this.form.products.map((itemProduct) => {
        const quantity = itemProduct.quantity;
        const productQuantity = itemProduct.product
          ? Number(itemProduct.product.quantity)
          : 0;
        const reservesQuantity = itemProduct.order_product_reserves
          ? Number(itemProduct.order_product_reserves.quantity)
          : 0;

        if (quantity > productQuantity + reservesQuantity) {
          validate = false;
        }
      });
      return validate;
    },
    validateShipmentMethodsForOrderStatus() {
      const orderStatus = this.orderStatusList.data.filter(
        (deliveryTypeItem) => deliveryTypeItem.id === this.form.order_status_id
      )[0];
      if (orderStatus.payment_id) {
        this.form.payment_method_id = orderStatus.payment_id;
      }
    },
    getHouseTitle() {
      this.form.order_data_shipments.house_name = this.form.order_data_shipments.house;
    },
    changeCityName(houseName) {
      this.form.order_data_shipments.city_name = houseName;
    },
    changeHouseName(houseName) {
      this.form.order_data_shipments.house_name = houseName;
    },
    allPrice() {
      let allPrice = 0;
      if (this.form.products) {
        this.form.products.map((item) => {
          allPrice += Number(item.quantity) * Number(item.price);
        });
        this.form.total_cost = allPrice;
        return allPrice;
      } else {
        this.form.total_cost = allPrice;
        return 0;
      }
    },
    allCount() {
      let allCount = 0;
      if (this.form.products) {
        this.form.products.map((item) => {
          allCount += Number(item.quantity);
        });
        return allCount;
      } else {
        return 0;
      }
    },
    allUpPrice() {
      let allPrice = 0;
      if (this.tableUpItems.length) {
        this.tableUpItems.map((item) => {
          allPrice += Number(item.price) * Number(item.count);
        });
      }
      return allPrice;
    },
    showProduct(item) {
      this.$emit("showProduct", item.product_id);
    },
    showMassagePopup() {
      this.$emit("showMassage", { status: true, form: this.form });
    },
    showCallPopup() {
      this.$emit("showCallPopup", {
        status: true,
        form: this.form,
        order_id: this.order ? this.order.id : null,
      });
    },
    changeHotOrder() {
      if (!this.form.hot_order) {
        this.form.hot_order = 1;
        this.$toasted.success(
          "Після збереження, замовлення буде переведене в термінове",
          {
            duration: 3000,
          }
        );
      } else {
        this.form.hot_order = 0;
        this.$toasted.error(
          "Після збереження, замовлення більше не буде терміновим",
          {
            duration: 3000,
          }
        );
      }
    },
    statusEdit(id) {
      if (id !== 15) {
        return true;
      } else {
        if (this.profile.user_group_id === 26) {
          return true;
        } else {
          return false;
        }
      }
    },
    historyDate(date) {
      return moment(date).format("HH:mm");
    },
    historyDelivery(id) {
      const resultType = this.deliveryType.filter(
        (deliveryTypeItem) => deliveryTypeItem.id === id
      )[0];
      return resultType.title;
    },
    historyDeliveryType(id) {
      const resultType = this.typeDeliveryList.filter(
        (deliveryTypeItem) => deliveryTypeItem.id === id
      )[0];
      return resultType.title;
    },
    historyOrderStatusType(id) {
      const resultType = this.orderStatusList.data.filter(
        (deliveryTypeItem) => deliveryTypeItem.id === id
      )[0];
      return resultType.title;
    },
    historyOrderDepartmentType(id) {
      const resultType = this.departments.data.filter(
        (departmentstem) => departmentstem.id === id
      )[0];
      return resultType.title;
    },
    historyOrderCancelType(id) {
      const resultType = this.cancelReasonList.filter(
        (departmentstem) => Number(departmentstem.id) === Number(id)
      )[0];
      return resultType.title;
    },
    historyOrderPaymentMethod(id) {
      const resultType = this.paymentMethods.data.filter(
        (deliveryTypeItem) => deliveryTypeItem.id === id
      )[0];
      return resultType.title;
    },
    historyEmployee(id) {
      const resultType = this.users.filter((userItem) => userItem.id === id)[0];
      return resultType.name;
    },
    checkIncludeStreet() {
      if (
        this.form.order_data_shipments.street &&
        !this.npStreetList.some(
          (item) => item.id === this.form.order_data_shipments.street
        )
      ) {
        this.npStreetList.push({
          id: this.form.order_data_shipments.street,
          title: this.form.order_data_shipments.street_name,
        });
      }
    },
    checkIncludeNpWarehouse() {
      if (
        this.form.order_data_shipments.warehouse &&
        !this.npWarehousesListClient.some(
          (item) => item.id === this.form.order_data_shipments.warehouse
        )
      ) {
        this.npWarehousesListClient.push({
          id: this.form.order_data_shipments.warehouse,
          title: this.form.order_data_shipments.warehouse_name
            ? this.form.order_data_shipments.warehouse_name
            : "Відсутня назва",
        });
      }
    },
    ...mapActions({
      getUsers: "contacts/getUsers",
      showOrder: "orders/showOrder",
      getUsersGroup: "contacts/getUsersGroup",
      getOrdersForPhone: "orders/getOrdersForPhone",
      getOrderFileLinks: "orders/getOrderFileLinks",
      getUkrPostIndexData: "orders/getUkrPostIndexData",
      removeProductInOrder: "orders/removeProductInOrder",
      getUkrPostIndexAddress: "orders/getUkrPostIndexAddress",
      getNpAreas: "plugins/getNpAreas",
      getNpWarehouses: "plugins/getNpWarehouses",
      getDeliveryCities: "orders/getDeliveryCities",
      getDeliveryWarehouses: "orders/getDeliveryWarehouses",
      getNpCity: "plugins/getNpCity",
      getNpStreet: "plugins/getNpStreet",
      getCustomers: "contacts/getCustomers",
    }),
    ...mapMutations({
      toggleShowAddProductPopUp: "config/toggleShowAddProductPopUp",
      changeIsUpsell: "orders/changeIsUpsell",
      changeOrder: "orders/changeOrder",
      changeOrderDepartmentId: "orders/changeOrderDepartmentId",
      changeOrderWholesaleStatus: "orders/changeOrderWholesaleStatus",
      showUserOrder: "orders/showUserOrder",
    }),
  },
  beforeDestroy() {
    if (this.order && this.order.status_open) {
      this.showOrder(this.order.id);
    }
  },
};
